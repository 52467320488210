'use client';

import { CrossCircledIcon } from '@radix-ui/react-icons';
import _ from 'lodash';
import { ChevronLeft } from 'lucide-react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { cn } from '@/lib/utils/twutils';

type View = {
  id: string;
  name: string;
  component: ReactNode;
};

type SheetStackContext = {
  isOpen: boolean;
  views: View[];
  setOpen: Dispatch<SetStateAction<boolean>>;
  pushView: (view: View) => void;
  popView: (id?: string) => void;
  width: number;
  setWidth: Dispatch<SetStateAction<number>>;
};

export const SheetStackContext = createContext<SheetStackContext>({} as any);

export function SheetStackProvider(props: { children: ReactNode }) {
  const [views, setViews] = useState<View[]>([]);
  const [isOpen, setOpen] = useState(false);
  const [width, setWidth] = useState(540);

  const popView = useCallback((id?: string) => {
    if (id) {
      setViews((_views) => [..._views].filter((v) => v.id !== id));
    } else {
      setViews((_views) => [..._views.slice(0, _views.length - 1)]);
    }
  }, []);

  return (
    <SheetStackContext.Provider
      value={{
        isOpen,
        views,
        setOpen,
        pushView: (view) => {
          setViews((_views) => {
            if (_views.find((v) => v.id === view.id)) return [..._views];
            return [..._views, view];
          });
        },
        popView,
        width,
        setWidth,
      }}
    >
      {props.children}
    </SheetStackContext.Provider>
  );
}

export default function SheetStack() {
  const { isOpen, setOpen, views, width, setWidth } = useContext(SheetStackContext);
  const isDragging = useRef(false);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    isDragging.current = true;
    e.preventDefault();
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging.current) return;
      const newWidth = window.innerWidth - e.clientX;
      setWidth(Math.max(300, Math.min(1600, newWidth)));
    };

    const handleMouseUp = () => {
      isDragging.current = false;
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [setWidth]);

  return (
    <div
      className={cn('bg-slate-950/0 h-full w-full absolute inset-0 transition-all duration-300 pointer-events-none z-10', { 'pointer-events-auto': isOpen })}
    >
      <div
        onClick={() => {
          setOpen(false);
        }}
        className={cn('absolute left-0 top-0 bottom-0 w-[calc(100vw-540px)] translate-x-full transition-colors duration-500', {
          'translate-x-0 bg-zinc-950/40': isOpen,
        })}
        style={{ width: `calc(100vw - ${width}px)` }}
      />
      <div
        className={cn('bg-background overflow-hidden absolute right-0 top-0 bottom-0 border-l drop-shadow translate-x-full transition-transform duration-300', {
          'translate-x-0': isOpen,
        })}
        style={{ width: `${width}px` }}
      >
        <div className="absolute left-0 top-0 bottom-0 w-4 z-20 cursor-ew-resize hover:bg-gray-300/10 transition-colors" onMouseDown={handleMouseDown} />
        {views.map((view, index) => {
          return (
            <SheetStackView
              key={view.id + `${index}`}
              view={view}
              backText={index === views.length - 1 && views.length > 1 ? views[index - 1]?.name?.slice(0, 12) : undefined}
            />
          );
        })}
      </div>
    </div>
  );
}

function SheetStackView(props: { view: View; backText?: string }) {
  const { popView, setOpen } = useContext(SheetStackContext);

  return (
    <div className="absolute inset-0 bg-background">
      <div className="border-b h-14 flex items-center justify-between drop-shadow px-5 relative">
        <span className="text-lg absolute left-0 right-0 text-center">{props.view.name}</span>
        {props.backText ? (
          <span className="flex items-center cursor-pointer relative" onClick={() => popView(props.view.id)}>
            <ChevronLeft />
            {props.backText}...
          </span>
        ) : (
          <CrossCircledIcon className="w-5 h-5 relative cursor-pointer" onClick={() => setOpen(false)} />
        )}
        <div className="w-5 h-5"></div>
      </div>
      <div className="px-4 pt-4 overflow-scroll max-h-full bg-background">{props.view.component}</div>
    </div>
  );
}
