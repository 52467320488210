'use client';

import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { signIn } from 'next-auth/react';

export default function SignIn() {
  return (
    <main className="flex items-center justify-center h-screen w-full">
      <Card className=" flex flex-col items-center">
        <CardHeader>
          <CardTitle>Sign In</CardTitle>
        </CardHeader>
        <CardContent className="flex flex-col px-24 space-y-2">
          <Button
            onClick={() => {
              signIn('google', { callbackUrl: '/admin/clients' });
            }}
          >
            Sign in with Google
          </Button>
        </CardContent>
      </Card>
    </main>
  );
}
