import(/* webpackMode: "eager" */ "/app/apps/portal/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["TrpcProvider"] */ "/app/apps/portal/app/TrpcProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SheetStackProvider"] */ "/app/apps/portal/components/display/SheetStack.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/apps/portal/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/app/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"dm_sans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
